import * as React from "react"
import { isBrowser, IsLoggedIn } from "../constants/options";
import useStores from "../hooks/use-stores"
import { navigate } from "gatsby";
import { Box, Typography } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, paddingValue, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: paddingValue }}>
          {children}
        </Box>
      )}
    </div>
  )
}

export default TabPanel;
